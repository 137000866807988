export const SPECS = {
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  showShippingMethodCashierExpressButton: 'specs.stores.ShowShippingMethodInApplePayModal',
  EcomPlatformCartAndCheckout: 'specs.stores.EcomPlatformCartAndCheckout',
  AddPickupPointsToCart: 'specs.stores.AddPickupPointsToCart',
  SecureCheckoutVelo: 'specs.stores.ShowHideSecureCheckoutVelo',
  PickupPointsBmPhase1: 'specs.stores.PickupPointsBmPhase1',
  DisplayMixedCart: 'specs.stores.DisplayMixedCart',
  DisplayViolations: 'specs.stores.DisplayViolations',
  HidePaymentLabelForFreeOfflineItems: 'specs.stores.HidePaymentLabelForFreeOfflineItems',
  UseNewCheckoutInFastFlow: 'specs.stores.UseNewCheckoutInFastFlow',
  ShouldEnableANonClickableLineItem: 'specs.stores.ShouldEnableANonClickableLineItem',
  ShouldReportIsMember: 'specs.stores.ShouldReportIsMember',
  CartTypeMixedVerticals: 'specs.stores.CartTypeMixedVerticals',
  RemoveCartTypeIsNonShippableCart: 'specs.stores.RemoveCartTypeIsNonShippableCart',
  UseExpressCheckoutButtonsLibraryForCart: 'specs.stores.UseExpressCheckoutButtonsLibraryForCart',
  DisableExpressCheckoutButtonsWhenPayLaterInCart: 'specs.stores.DisableExpressCheckoutButtonsWhenPayLaterInCart',
  CatchNotPremiumExceptionOnCreateCheckout: 'specs.stores.CatchNotPremiumExceptionOnCreateCheckout',
  CartOOIThunderboltCSSOptimization: 'specs.stores.CartOOIThunderboltCSSOptimization',
  UseNewCheckoutForNonStoresFlows: 'specs.stores.UseNewCheckoutForNonStoresFlows',
  GetCartWithViolations: 'specs.stores.GetCartWithViolations',
} as const;
